<template>
    <el-table :default-sort="preSelectedSort" :data="receiptsData" @sort-change="sortChange" v-loading="$waiting.is('loading')" @selection-change="handleSelectionChange" size="mini">
        <el-table-column prop="status" label="Status" align="center" sortable="custom" width="110">
            <template slot-scope="scope">{{ translate(scope.row.status) }}</template>
        </el-table-column>
        <el-table-column prop="type" label="Typ" align="center" sortable="custom" width="160">
            <template slot-scope="scope">{{ translate(scope.row.type) }}</template>
        </el-table-column>
        <el-table-column prop="clientName" label="Klient" sortable="custom" show-overflow-tooltip>
            <template slot-scope="scope">FE {{ scope.row.clientNumber }}, {{ scope.row.clientName }}</template>
        </el-table-column>
        <el-table-column prop="purpose" label="Syfte" sortable="custom" show-overflow-tooltip />
        <el-table-column prop="amount" align="right" label="Belopp" sortable="custom" width="140">
            <template slot-scope="scope">{{ scope.row.amount | swedishNumberFormat }}</template>
        </el-table-column>
        <el-table-column prop="date" label="Datum" sortable="custom" width="100">
            <template slot-scope="scope">
                <p>
                    {{ formatDate(scope.row.date) }}
                </p>
            </template>
        </el-table-column>
        <el-table-column prop="isPayed" label="Utbetald" sortable="custom" width="120">
            <template slot-scope="scope">
                <p>
                    {{ formatDate(scope.row.isPayed) }}
                </p>
            </template>
        </el-table-column>
        <el-table-column prop="registrationDate" label="Registrerings Datum" sortable="custom" width="200">
            <template slot-scope="scope">
                <p>
                    {{ formatDate(scope.row.registrationDate) }}
                </p>
            </template>
        </el-table-column>
        <el-table-column prop="receiptNumber" label="Kvitto nummer" sortable="custom" width="160" />
        <el-table-column prop="underlayIsMissing" label="" width="40">
            <template slot-scope="scope"><i v-if="scope.row.hasAttachment" class="fa-light fa-paperclip-vertical fa-lg"/></template>
        </el-table-column>
        <el-table-column prop="id" width="50">
            <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" content="Details" placement="left">
                    <router-link
                        :to="{
                            path: `receipts/${scope.row.id}`,
                            query: {
                                ...($route.query.query ? { query: $route.query.query } : {}),
                                ...($route.query.sortBy ? { sortBy: $route.query.sortBy } : {}),
                                ...($route.query.sortByType ? { sortByType: $route.query.sortByType } : {}),
                                ...($route.query.status ? { status: $route.query.status } : {}),
                                ...($route.query.currency ? { currency: $route.query.currency } : {}),
                                ...($route.query.dateFrom ? { dateFrom: $route.query.dateFrom } : {}),
                                ...($route.query.dateTo ? { dateTo: $route.query.dateTo } : {}),
                                ...($route.query.clientId ? { clientId: $route.query.clientId } : {}),
                                ...($route.query.test ? { test: $route.query.test } : {}),
                                ...($route.query.category ? { category: $route.query.category } : {}),
                            },
                        }"
                    >
                        <el-button type="primary" class="p-1">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7" />
                            </svg>
                        </el-button>
                    </router-link>
                </el-tooltip>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
import queryString from "query-string";
import moment from "moment";
export default {
    props: {
        receiptsData: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            sortBy: null,
            sortByType: null,
        };
    },

    created() {
        this.assignInitValues();
    },

    computed: {
        preSelectedSort() {
            if (!this.sortBy) return {};
            return {
                prop: this.sortBy,
                order: this.sortByType,
            };
        },
    },

    methods: {
        translate(string) {
            const translation = {
                Registered: "Registrerad",
                Approved: "Godkänd",
                Denied: "Nekad",
                Supplement: "Kompletera",
                Supplemented: "Kompleterad",
                Deleted: "Raderad",
                Proposal: "Förslag",
                PrivateExpenses: "Privat utlägg",
                CompanyAccount: "Företagskort/-konto",
                CompanyCreditCard: "Företagskreditkort",
                Draft: "Utkast",
            };
            return translation[string];
        },

        assignInitValues() {
            const { sortBy, sortByType } = queryString.parse(location.search);
            this.sortBy = sortBy;
            this.sortByType = sortByType;
        },

        formatDate(date) {
            if (date == null) return "";
            return moment(date).format("YYYY-MM-DD");
        },

        sortChange(event) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.sortBy = event.order ? event.prop : undefined;
            query.sortByType = event.order || undefined;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("sortTypeChanged");
        },

        handleSelectionChange() {},
    },
};
</script>
<style>
.el-popover__title {
    color: #fff;
}
</style>
